import React from 'react'
import clsx from 'clsx'
import * as styles from './Box.module.scss'

const Box = ({
  letter = '',
  color = 'empty',
  className: classNameProp
}) => {
  const className = clsx(
    styles.Box,
    styles[color],
    classNameProp
  )
	return (
    <div className={className}>
      <div className={styles.letter}>{letter}</div>
    </div>
  )
}

export default Box